/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-floating-promises */

import axios from "axios"
import type { AxiosResponse, AxiosRequestConfig } from "axios"
import * as React from "react"
import { flushSync } from "react-dom"
import { useMutation, useQueryClient } from "react-query"
import z from "zod"

import { useCertificateContext } from "context/CertificateContext"

import { extractVariables } from "./helpers"
import {
  GenerateCertificateOutput,
  AvailableLanguages,
  GenerateInput,
  InlineEditSections,
} from "./types"
import {
  ECertificateTypes,
  ICertificate,
  certificateKeys,
  useCertificateMutation,
  useSystemInfo,
} from "hooks/data"
import { flattenBeurteilungsTexte } from "@/components/elements/InlineEdit/helpers"
import { useCurrentUserContext } from "context/CurrentUserContext"

const finalCertificateTypes = [
  ECertificateTypes.WORK,
  ECertificateTypes.CONFIRMATION,
  ECertificateTypes.INTERNSHIP,
]


export const rewordCertificateSentence = async (input: GenerateInput) => {
  return axios
    .post<AxiosRequestConfig, AxiosResponse<GenerateCertificateOutput>>(
      "/api/certificates/reword",
      input
    )
    .then((response) => {
      const data = GenerateCertificateOutput.parse(response.data)

      return data
    })
}

export const useGetLatestCertificate = () => {
  const { certificate } = useCertificateContext()
  const queryClient = useQueryClient()

  return React.useCallback(() => {
    if (!certificate) {
      return null
    }

    const latestCertificate = queryClient.getQueryData<ICertificate>(
      certificateKeys.one(certificate?.ZeugnisID)
    )

    return latestCertificate
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certificate?.ZeugnisID])
}

export const useGetIsIntroEdited = () => {
  const [edited, setEdited] = React.useState(false)
  const getCertificate = useGetLatestCertificate()

  React.useEffect(() => {
    const certificate = getCertificate()
    if (!certificate) {
      return
    }

    const isEdited = certificate.IntroCustomizedTexte !== certificate.IntroTemplateTexte
    setEdited(isEdited)
  }, [getCertificate])

  return edited
}

export const useAiExperiment = () => {
  // TODO: Decide on logic for beta
  const { aiExperiment } = useCertificateContext()
  return aiExperiment
}

export type Generate = {
  sentence: string;
  original: string;
  temperature?: number;
  language: AvailableLanguages;
  type: "reword-sentence" | "certificate-language-changed" | "certificate-type-changed";
}

const getSimpleCertificateType = (certificateTypeId: ICertificate['CatZeugnisTyp']['ID']) => {
  return finalCertificateTypes.includes(certificateTypeId)
    ? "final"
    : "interim"
}
export const useGenerateCertificateIntro = () => {
  const { toggleFetchingOnSection, storeDebugData, setEvaluationsSelectedNodeId } = useCertificateContext()
  const getCertificate = useGetLatestCertificate()
  const [autoTemperature, setAutoTemperature] = React.useState(0)
  const { isDev } = useCurrentUserContext();

  const mutation = useMutation(
    async (config: {
      type: Generate['type'];
      section?: InlineEditSections;
      sentence?: string;
      temperature?: number;
      CatBeurteilungsOptionID?: number;
    }) => {
      const certificate = getCertificate()

      if (!certificate) {
        return Promise.reject('No certificate')
      }

      if (!config.section) {
        return Promise.reject('No section')
      }

      const changedSection = config.section

      toggleFetchingOnSection(changedSection, true)

      const simpleCertificateType = getSimpleCertificateType(certificate.CatZeugnisTyp.ID)

      switch (config.type) {
        case 'certificate-language-changed':
        case 'certificate-type-changed': {
          let text = getCustomTextForSection({
            certificate,
            section: changedSection
          });

          if (config.CatBeurteilungsOptionID) {
            setEvaluationsSelectedNodeId(config.CatBeurteilungsOptionID)
            const evaluationText = certificate.Beurteilungen.find(
              (evaluation) => evaluation.CatBeurteilungsOptionID === config.CatBeurteilungsOptionID
            )?.CustomTemplate

            text = evaluationText
          }

          return rewordCertificateSentence({
            original: text ?? "",
            sentence: text ?? "",
            temperature: autoTemperature,
            language: certificate.CatSprache.ID as AvailableLanguages,
            certificateType: simpleCertificateType,
            type: config.type,
            debug: isDev,
          })
        }
        case 'reword-sentence':
          return rewordCertificateSentence({
            original: certificate.IntroCustomizedTemplate ?? "",
            sentence: config.sentence ?? "",
            temperature: autoTemperature,
            language: certificate.CatSprache.ID as AvailableLanguages,
            certificateType: simpleCertificateType,
            type: config.type,
            debug: isDev,
          })
        default:
          throw new Error('Invalid type')
      }
    },
    {
      onSettled: (response, _err, variables) => {
        if (!variables.section) {
          return
        }

        if (response?.status === 'success' && response.debug) {
          storeDebugData(response.debug)
        }

        const changedSection = variables.section;

        toggleFetchingOnSection(changedSection, false)
      },
      onError: (error) => {
        console.log("error", error)
      },
    }
  )

  function generate(config: {
    type: Generate['type'];
    sentence?: string;
    temperature?: number;
    section?: InlineEditSections
    CatBeurteilungsOptionID?: number
  }) {
    flushSync(() => {
      setAutoTemperature((prev) => {
        if (config.type === 'reword-sentence') {
          return config.temperature ?? prev + 0.2
        }

        return config.temperature ?? 0;
      })
    })

    return mutation.mutateAsync(config)
  }

  return {
    ...mutation,
    generate,
  }
}

export const getOriginalTextForSection = ({
  section,
  certificate
}: {
  section: InlineEditSections;
  certificate?: ICertificate | null
}) => {
  switch (section) {
    case 'intro':
      return certificate?.IntroTemplateTexte
    case 'conclusion':
      return certificate?.SchlussformelTexte.map(conclusionItem => {
        return conclusionItem.SchlussformelTexte[0]?.Text
      }).join(" ").trim().replaceAll("~", "")
    case 'education':
      return certificate?.AusbildungsText.replaceAll("~", "")
    case 'evaluations':
      return '';
    default:
      throw new Error('Invalid section')
  }
}

export const getCustomTextForSection = ({
  section,
  certificate
}: {
  section: InlineEditSections;
  certificate?: ICertificate | null
}) => {
  switch (section) {
    case 'intro':
      return certificate?.IntroCustomizedTexte
    case 'conclusion':
      return certificate?.ConclusionCustomizedTexte?.trim().replaceAll("~", "")
    case 'education':
      return certificate?.AusbildungsCustomizedTexte ? certificate?.AusbildungsCustomizedTexte.replaceAll("~", "") : null
    case 'evaluations': {
      if (!certificate) {
        return
      }

      const flatEvaluations = flattenBeurteilungsTexte(certificate.BeurteilungsTexte, certificate.Beurteilungen);

      const isUsingCustomTemplate = flatEvaluations.some(evaluation => evaluation.UseCustomTemplate)

      // this is a hack because we don't have the original text for the evaluation that is using
      // custom template so we cannot compare it. We just know if it's used or not
      // and we want to keep the same return type as the other sections
      return isUsingCustomTemplate ? 'custom template in evaluations' : ''
    }
    default:
      throw new Error('Invalid section')
  }
}

export const getDefaultValuesForSectionKeys = ({
  certificate,
  section,
  overrideText,
}: {
  certificate: ICertificate | null;
  section: InlineEditSections;
  overrideText?: string;
}) => {
  const originalText = getOriginalTextForSection({ section, certificate })

  switch (section) {
    case 'intro': {
      const text = overrideText || originalText
      return {
        IntroCustomizedTemplate: text,
        IntroCustomizedTexte: text,
      }
    }
    case 'conclusion': {
      const text = overrideText || originalText
      return {
        ConclusionCustomizedTemplate: text,
        ConclusionCustomizedTexte: text,
      }
    }
    case 'education': {
      const text = overrideText || originalText
      return {
        AusbildungsCustomizedTemplate: text,
        AusbildungsCustomizedTexte: text,
      }
    }
    case 'evaluations':
      return {}
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Invalid section: ${section}`);
  }
}

export type UpdateSection = {
  section: Exclude<InlineEditSections, 'evaluations'>;
  overrideText?: string;
}

export type UpdateEvaluationSection = {
  section: 'evaluations';
  changes?: {
    CatBeurteilungsOptionID: number;
    text: string;
  }[]
}

export type UpdateSections = UpdateSection | UpdateEvaluationSection

export const useUpdateInlineSections = () => {
  const getCertificate = useGetLatestCertificate()
  const { mutateAsync: updateCertificateAsync, status } = useCertificateMutation()
  const getNewEvaluationOptions = useGetNewEvaluationOptions();

  const updateSections = React.useCallback((sections: UpdateSections[]) => {
    const certificate = getCertificate();
    if (!certificate) {
      return;
    }

    const nonEvaluationSections = sections.filter(section => section.section !== 'evaluations') as UpdateSection[]
    const keys: Partial<ICertificate> = nonEvaluationSections.reduce((acc, { section, overrideText }) => {
      const sectionKeys = getDefaultValuesForSectionKeys({ certificate, section, overrideText });
      return {
        ...acc,
        ...sectionKeys,
      };
    }, {});

    if (sections.some(section => section.section === 'evaluations')) {
      const evaluationSection = sections.find(section => section.section === 'evaluations') as UpdateEvaluationSection

      if (!evaluationSection.changes) {
        return;
      }

      const newOptions = getNewEvaluationOptions(evaluationSection.changes);

      keys.Beurteilungen = newOptions;
    }

    return updateCertificateAsync({
      ...certificate,
      ...keys,
      toastConfig: {
        enabled: false,
      },
    })
  }, [getCertificate, getNewEvaluationOptions, updateCertificateAsync]);

  return {
    updateSections,
    status,
  };
}

const initialCustomTemplateStatus = {
  inlineEdited: false,
  used: false,
  hasVariables: false,
  isReady: false,
  hasDiff: false,
}

export const useCustomTemplateStatus = (section: InlineEditSections) => {
  const getLatestCertificate = useGetLatestCertificate();
  const { certificate } = useCertificateContext();
  const [isUsedForInlineEdit, setIsUsedForInlineEdit] = React.useState(initialCustomTemplateStatus);

  const check = React.useCallback(() => {
    const latestCertificate = getLatestCertificate();
    if (!latestCertificate) {
      return initialCustomTemplateStatus
    }

    const keys = getDefaultValuesForSectionKeys({
      certificate: latestCertificate,
      section,
    });

    const [template, text] = Object.values(keys);

    const hasVariables = extractVariables(template ?? "").length > 0 || extractVariables(text ?? "").length > 0;
    const customFieldsAreUsed = !!template || !!text;
    const customFieldsAreUsedByInlineEdit = customFieldsAreUsed && !hasVariables
    const hasDiff = getCustomTextForSection({ section, certificate: latestCertificate }) !== getOriginalTextForSection({ section, certificate: latestCertificate })

    if (section === 'education' && (latestCertificate.HideEducationSection || !Boolean(latestCertificate.AusbildungsText))) {
      return {
        inlineEdited: false,
        used: false,
        hasVariables: false,
        isReady: true,
        hasDiff: false,
      }
    }

    console.log('check', section, customFieldsAreUsedByInlineEdit, customFieldsAreUsed, hasVariables, hasDiff)

    const results = {
      inlineEdited: customFieldsAreUsedByInlineEdit,
      used: customFieldsAreUsed,
      hasVariables,
      isReady: true,
      hasDiff
    }

    setIsUsedForInlineEdit(results);
    return results;
  }, [getLatestCertificate, section])

  React.useEffect(() => {
    check()
  }, [certificate, check])

  React.useEffect(() => {
    check()
  }, [check])

  return {
    ...isUsedForInlineEdit,
    check,
  };
}

export const useEnableCustomTemplates = (sections: InlineEditSections[]) => {
  const { mutate, ...mutation } = useCertificateMutation()
  const getCertificate = useGetLatestCertificate();

  const introStatus = useCustomTemplateStatus('intro')

  React.useEffect(() => {
    const certificate = getCertificate();

    if (!certificate) {
      return;
    }

    const sectionFlags: boolean[] = [];
    const payload: Partial<ICertificate> = {};

    for (const section of sections) {
      const customText = getCustomTextForSection({ section, certificate })
      const defaultText = getOriginalTextForSection({ section, certificate });
      switch (section) {
        case 'intro': {
          Object.assign(payload, getDefaultValuesForSectionKeys({
            certificate,
            section,
            overrideText: customText ?? defaultText ?? undefined
          }));
          const isOn = certificate.UseIntroCustomizedTemplate;
          sectionFlags.push(isOn);

          if (!isOn) {
            payload.UseIntroCustomizedTemplate = true;
            payload.IntroCustomizedTexte = payload.IntroCustomizedTemplate;
          }
          break;
        }
        case 'conclusion': {
          Object.assign(payload, getDefaultValuesForSectionKeys({
            certificate,
            section,
            overrideText: defaultText ?? undefined
          }));
          const isOn = certificate.UseConclusionCustomizedTemplate;
          sectionFlags.push(isOn);

          if (!isOn) {
            payload.UseConclusionCustomizedTemplate = true;
            payload.ConclusionCustomizedTexte = payload.ConclusionCustomizedTemplate;
          }
          break;
        }
        case 'education': {
          Object.assign(payload, getDefaultValuesForSectionKeys({
            certificate,
            section,
            overrideText: defaultText ?? undefined
          }));
          const isOn = certificate.UseAusbildungsCustomizedTemplate;
          sectionFlags.push(isOn);

          if (!isOn) {
            payload.UseAusbildungsCustomizedTemplate = true;
            payload.AusbildungsCustomizedTexte = payload.AusbildungsCustomizedTemplate;
          }
          break;
        }
        case 'evaluations':
          break;
        default:
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          throw new Error(`Invalid section: ${section}`);
      }
    }

    const areEnabled = sectionFlags.every(flag => flag);

    if (areEnabled) {
      return;
    }

    mutate({
      ...certificate,
      ...payload,
      toastConfig: {
        enabled: false,
      },
    })
  }, [sections, mutate, getCertificate, introStatus.inlineEdited])

  return mutation;
}

export const InlineEditMetadata = z.record(
  z.string(),
  z.object({
    enabled: z.boolean(),
  })
)

export type InlineEditMetadata = z.infer<typeof InlineEditMetadata>

export const INLINE_EDIT_FLAG_KEY = 'SKRIBA_INLINE_EDIT'

export const useToggleLocalInlineEdit = () => {
  const { aiExperiment, setAiExperiment, certificate } = useCertificateContext()
  const { data: systemInfoData } = useSystemInfo();

  React.useEffect(() => {
    if (!systemInfoData || !certificate || !systemInfoData.Feature3) {
      return;
    }

    setAiExperiment(prev => ({
      ...prev,
      loaded: true,
      metadata: {},
    }))

    const localStorageValue = localStorage.getItem(INLINE_EDIT_FLAG_KEY) ?? '{}';
    const flags = new URLSearchParams(window.location.hash.split('?')[1]).get('flags');
    const isInlineEditFlagOnUrl = flags?.includes('inline-edit');

    const parsed = InlineEditMetadata.safeParse(JSON.parse(localStorageValue));
    const certificateId = certificate.ZeugnisID;
    const isInlineEditAlreadyEnabledOnCertificate = parsed.success && parsed.data[certificateId]?.enabled;

    if (!parsed.success) {
      return;
    }

    if (!isInlineEditAlreadyEnabledOnCertificate && !isInlineEditFlagOnUrl) {
      return;
    }

    const newMetadata = {
      ...parsed.data,
      [certificateId]: {
        enabled: true
      }
    }

    if (!isInlineEditAlreadyEnabledOnCertificate) {
      localStorage.setItem(INLINE_EDIT_FLAG_KEY, JSON.stringify(newMetadata))
    }

    setAiExperiment(prev => ({
      ...prev,
      loaded: true,
      metadata: newMetadata,
    }))

  }, [certificate, systemInfoData, setAiExperiment])

  const toggle = React.useCallback((newCertId?: number) => {
    setAiExperiment(prev => {
      const metadata = prev.metadata ?? {};
      const certificateId = newCertId ?? certificate?.ZeugnisID;

      if (!certificateId) {
        return prev;
      }

      const isEnabled = metadata[certificateId]?.enabled ?? false;

      const updated = {
        ...metadata,
        [certificateId]: {
          enabled: !isEnabled,
        }
      }

      localStorage.setItem(INLINE_EDIT_FLAG_KEY, JSON.stringify(updated))

      return {
        ...prev,
        metadata: updated,
      }
    })
  }, [certificate?.ZeugnisID, setAiExperiment]);

  const isCertificateEnabled = React.useMemo(() => {
    const certificateId = certificate?.ZeugnisID;

    if (!certificateId) {
      return false;
    }

    return aiExperiment.metadata?.[certificateId]?.enabled ?? false;
  }, [aiExperiment.metadata, certificate?.ZeugnisID]);

  return {
    toggle,
    enabled: isCertificateEnabled,
  }
}

export const useGetNewEvaluationOptions = () => {
  const getLatestCertificate = useGetLatestCertificate();

  return (evaluationOptions: { CatBeurteilungsOptionID: number; text: string }[]) => {
    const certificate = getLatestCertificate();
    if (!certificate || !certificate?.BeurteilungsTexte) {
      return;
    }

    const flatEvaluations = flattenBeurteilungsTexte(certificate.BeurteilungsTexte, certificate.Beurteilungen);

    return flatEvaluations.map((option) => {
      const matchedOption = evaluationOptions.find(
        (evalOption) => evalOption.CatBeurteilungsOptionID === option.CatBeurteilungsOptionID
      );

      if (matchedOption) {
        return {
          CatBeurteilungsOptionID: option.CatBeurteilungsOptionID,
          UseCustomTemplate: true,
          CustomTemplate: matchedOption.text,
        };
      }

      return {
        CatBeurteilungsOptionID: option.CatBeurteilungsOptionID,
        UseCustomTemplate: option.UseCustomTemplate,
        CustomTemplate: option.CustomTemplate,
      };
    });
  };
};

export const useGetCustomEvaluationOptions = () => {
  const getLatestCertificate = useGetLatestCertificate();

  return () => {
    const certificate = getLatestCertificate();
    if (!certificate || !certificate?.BeurteilungsTexte) {
      return;
    }

    const flatEvaluations = flattenBeurteilungsTexte(certificate.BeurteilungsTexte, certificate.Beurteilungen);

    return flatEvaluations.map((option) => {
      return {
        CatBeurteilungsOptionID: option.CatBeurteilungsOptionID,
        UseCustomTemplate: option.UseCustomTemplate,
        CustomTemplate: option.CustomTemplate,
      };
    }).filter(option => option.UseCustomTemplate && option.CustomTemplate);
  };
}
