import { Combobox } from "@headlessui/react"
import classNames from "classnames"
import { TFunction } from "i18next"
import * as _ from "lodash-es"
import { XIcon } from "lucide-react"
import { ChangeEvent, Key, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import {
  Checkmark as CheckmarkIcon,
  ChevronUpDown as ChevronUpDownIcon,
} from "assets/icons"
import { isEmpty } from "utils/array"

import FloatPlacement from "./FloatPlacement"

export type TOption<TValue> = {
  value: TValue
  primary: string
  label?: string | null
}

type TProps<TValue> = {
  onSearchTermChange: (searchTerm: string) => void
  options?: TOption<TValue>[]
  defaultOption?: TOption<TValue>
  onChange?: (option?: TOption<TValue>) => void
  placeholder?: string | null
  disabled?: boolean
  minSearchTermLength?: number
  className?: string
  label?: TFunction | string | null
  showReset?: boolean
}

export default function CustomCombobox<TValue>(
  props: TProps<TValue>
): JSX.Element {
  const {
    onSearchTermChange,
    options = [],
    placeholder,
    onChange,
    disabled,
    defaultOption,
    minSearchTermLength = 0,
    className,
    label,
    showReset,
  } = props

  const { t } = useTranslation("selectComponent")

  const placeholderValue = {
    value: -1 as unknown as TValue,
    primary: "",
  }

  const [chosenOption, setChosenOption] =
    useState<TOption<TValue>>(placeholderValue)

  useEffect(() => {
    if (defaultOption && (chosenOption.value as unknown as number) === -1) {
      setChosenOption(defaultOption)
    }
    if (defaultOption === undefined) {
      setChosenOption({ value: -1 as unknown as TValue, primary: "" })
    }
  }, [defaultOption, chosenOption.value])

  const nothingFound = isEmpty(options)

  return (
    <Combobox
      as="div"
      className={classNames("Combobox relative", className)}
      value={chosenOption}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(option: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setChosenOption(option)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        if (onChange) onChange(option)
      }}
      {...{ disabled }}
    >
      {label && (
        <span
          className={classNames(["mb-2 block text-sm font-bold text-black"])}
        >
          {label as string}
        </span>
      )}

      <FloatPlacement>
        <div className="relative">
          <Combobox.Input
            placeholder={placeholder ?? t("placeholder") ?? ""}
            className={classNames([
              "h-10 w-full rounded-md border-none text-base",
              "py-2 pl-3 pr-14 shadow-sm",
              "ring-1 ring-inset ring-black",
              "focus:outline-none focus:ring-2 focus:ring-inset focus:ring-ocean ",
              disabled &&
                "pointer-events-none select-none bg-gray-200 opacity-50",
            ])}
            onChange={_.debounce(
              ({ target }: ChangeEvent<HTMLInputElement>) => {
                if (target.value.length > minSearchTermLength) {
                  onSearchTermChange(target.value)
                }
              },
              300
            )}
            displayValue={(option: TOption<TValue>) => option.primary}
            autoComplete="off"
          />

          {defaultOption && showReset && (
            <button
              className="absolute inset-y-0 right-8 flex items-center hover:opacity-50"
              onClick={(e) => {
                e.stopPropagation()
                onChange && onChange(undefined)
              }}
            >
              <XIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </button>
          )}
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-3 focus:outline-none">
            <ChevronUpDownIcon className="icon" aria-hidden="true" />
          </Combobox.Button>
        </div>

        <Combobox.Options
          className={classNames([
            "z-10 mt-1 max-h-60",
            "w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg border border-black focus:outline-none",
          ])}
        >
          {nothingFound && (
            <div className="px-4 pb-2 pt-3">{t("nothingFound")}</div>
          )}
          {options.map((option) => (
            <Combobox.Option
            key={option.value as unknown as Key}
            value={option}
            className={({ active }) =>
              classNames([
                "relative cursor-default select-none py-2 pl-3 pr-9",
                active && "bg-clearSky",
              ])
            }
          >
              {({ active, selected }) => (
                <>
                  <div className="flex items-center">
                    {option.label && (
                      <span
                        className={classNames([
                          "inline-block rounded-lg px-2.5 py-0.5",
                          active ? "bg-gray-400 text-white" : "bg-gray-200",
                        ])}
                        aria-hidden="true"
                      >
                        {option.label}
                      </span>
                    )}
                    <span
                      className={`ml-1 ${
                        selected ? "font-semibold" : ""
                      }`}
                    >
                      {option.primary}
                    </span>
                  </div>

                  {selected && (
                    <span
                    className={classNames([
                      "text-black",
                      "absolute inset-y-0 right-0 flex items-center pr-4",
                      ])}
                    >
                      <CheckmarkIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  )}
                </>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </FloatPlacement>
    </Combobox>
  )
}
