/* eslint-disable import/order */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-redeclare */
import keyBy from "lodash/keyBy"
import { z } from "zod"
import {
  SmileySmile as SmileySmileIcon,
  SmileySmirk as SmileySmirkIcon,
  SmileyIndifferent as SmileySadIcon,
  Pencil as PencilIcon,
} from "assets/icons"

export type CertificateEvaluations = {
  CatBereichID: number
  Code: string
  BeurteilungsTexte: {
    CatKriteriumID: number
    CatBeurteilungLevelID: number
    CatBeurteilungsOptionID: number
    Text: string
  }[]
}

export type FlatEvaluation = {
  CatBereichID: number
  Code: string
  CatKriteriumID: number
  CatBeurteilungLevelID: number
  CatBeurteilungsOptionID: number
  Text?: string
  BeurteilungID?: number
  UseCustomTemplate: boolean
  CustomTemplate: string
}

const ExistingEvaluation = z.object({
  BeurteilungID: z.number().optional(),
  CatBeurteilungsOptionID: z.number(),
  CustomTemplate: z.string(),
  UseCustomTemplate: z.boolean(),
})

export type ExistingEvaluation = z.infer<typeof ExistingEvaluation>


export const AvailableTaskGroup = z.object({
  BeurteilungsOptionen: z.array(
    z.object({
      CatBeurteilungsLevel: z.object({
        Code: z.null(),
        DisplayName: z.string(),
        ID: z.number(),
      }),
      CatBeurteilungsOptionID: z.number(),
    })
  ),
})

export const AvailableCriteriaCategory = z.object({
  CatKriteriumID: z.number(),
  Aussagen: z.array(AvailableTaskGroup),
})

export const AvailableOptions = z.object({
  CatBereichID: z.number(),
  Kriterien: z.array(AvailableCriteriaCategory),
})

type AvailableOptions = z.infer<typeof AvailableOptions>

export function getNextEvaluation(
  obj: AvailableOptions[],
  currentEvaluation: FlatEvaluation,
  newRate: number
) {
  const { CatBereichID, Code, CatKriteriumID, CatBeurteilungsOptionID } =
    currentEvaluation

  if (!obj) {
    throw new Error("No available options")
  }

  const kriterium = obj.find((bereich) => bereich?.CatBereichID === CatBereichID)

  if (!kriterium) {
    throw new Error("Kriterium not found")
  }

  const kriteriumKriterien = kriterium.Kriterien.find((krit) => krit.CatKriteriumID === CatKriteriumID)

  if (!kriteriumKriterien) {
    throw new Error("kriteriumKriterien not found")
  }

  const relevantAussagen = kriteriumKriterien.Aussagen.find((aussage) =>
    aussage.BeurteilungsOptionen.some(
      (option) => option.CatBeurteilungsOptionID === CatBeurteilungsOptionID
    )
  )?.BeurteilungsOptionen ?? []

  const newBeurteilungsOption = relevantAussagen.find(
    (option) => option.CatBeurteilungsLevel.ID === newRate
  )

  if (!newBeurteilungsOption) {
    throw new Error("New BeurteilungsOption not found")
  }

  return {
    CatBereichID: CatBereichID,
    Code: Code,
    CatKriteriumID: CatKriteriumID,
    CatBeurteilungLevelID: newRate,
    CatBeurteilungsOptionID: newBeurteilungsOption.CatBeurteilungsOptionID,
  }
}

export function flattenBeurteilungsTexte(
  data: CertificateEvaluations[],
  existingEvaluations: ExistingEvaluation[] = []
) {
  const flatArray: FlatEvaluation[] = []
  const existingEvaluationById = keyBy(
    existingEvaluations,
    "CatBeurteilungsOptionID"
  )

  data.forEach((item) => {
    const { CatBereichID, Code } = item

    item.BeurteilungsTexte.forEach((textItem) => {
      const existingEvaluation =
        existingEvaluationById[textItem.CatBeurteilungsOptionID]

      const flatItem = {
        CatBereichID,
        Code,
        BeurteilungID: existingEvaluation?.BeurteilungID,
        UseCustomTemplate: existingEvaluation?.UseCustomTemplate ?? false,
        CustomTemplate: existingEvaluation?.CustomTemplate ?? "",
        ...textItem,
      }

      flatArray.push(flatItem)
    })
  })

  return flatArray
}

export const EvaluationGrades = z.enum(["exceeds", "fullyMeets", "meets"])
export type EvaluationGrade = z.infer<typeof EvaluationGrades>

export const EvaluationNodeModes = z.enum(["edit", "selected", "fetching"])
export type EvaluationNodeMode = z.infer<typeof EvaluationNodeModes>

export const ToolbarNodeTypes = z.enum([
  ...EvaluationGrades.options,
  EvaluationNodeModes.enum.edit,
])
export type ToolbarNodeType = z.infer<typeof ToolbarNodeTypes>

export const evaluationsToIcon = {
  exceeds: <SmileySmileIcon />,
  fullyMeets: <SmileySmirkIcon />,
  meets: <SmileySadIcon />,
  edit: <PencilIcon />,
}

export const getGradeFromEvaluation = (evaluation: FlatEvaluation) => {
  switch (evaluation.CatBeurteilungLevelID) {
    case 1:
      return EvaluationGrades.enum.exceeds
    case 2:
      return EvaluationGrades.enum.fullyMeets
    case 3:
      return EvaluationGrades.enum.meets
    default:
      throw new Error("Invalid evaluation grade")
  }
}

export const getEvaluationFromGrade = (grade: EvaluationGrade) => {
  switch (grade) {
    case EvaluationGrades.enum.exceeds:
      return 1
    case EvaluationGrades.enum.fullyMeets:
      return 2
    case EvaluationGrades.enum.meets:
      return 3
    default:
      throw new Error("Invalid grade")
  }
}
