/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import Bubbles from "/assets/icons/bubbles.svg"

interface Props {
  title: string
  body: string
}

export default function EmptyState(props: Props): JSX.Element {
  const { title, body } = props

  return (
    <div className="relative">
      <img className="m-auto" src={Bubbles.src} />
      <div className="container absolute top-[30%] left-[50%] w-[360px] -translate-x-2/4 rounded-3xl bg-[#f2f5f6] p-8 text-center">
        <div className="text-lg font-semibold">{title}</div>
        <div className="text-m mt-3">{body}</div>
      </div>
    </div>
  )
}
