import { SortingState } from "@tanstack/react-table"
import classNames from "classnames"
import dayjs from "dayjs"
import Head from "next/head"
import { useRouter } from "next/router"
import {
  useEffect,
  useState,
  useMemo,
  ReactNode,
  ComponentProps,
  useCallback,
} from "react"
import { useTranslation } from "react-i18next"

import ComboboxEmployee from "@/components/data/ComboboxEmployee"
import ComboboxUser from "@/components/data/ComboboxUser"
import EmptyState from "@/components/elements/EmptyState"
import SelectGeneric from "@/components/forms/SelectGeneric"
import CertificatesTable, { CertificateRequestRow } from "@/components/pages/certificates/CertificatesTable"
import CreateNewCertificateModal from "@/components/pages/certificates/ModalCreateNewCertificate/CreateNewCertificateModal"
import useCertificatesRequestsTableRows from "@/components/pages/certificates/useCertificatesRequestsTableRows"
import {
  Duplicate as DuplicateIcon,
  Filter as FilterIcon,
  Garbage as GarbageIcon,
  HorizontalDots as HorizontalDotsIcon,
  Notes as NotesIcon,
  Pencil as PencilIcon,
  Plus as PlusIcon,
  Find as FindIcon,
} from "assets/icons"
import Button from "components/elements/Button"
import { DeleteConfirmationModal } from "components/elements/DeleteButton"
import Pill from "components/elements/Pill/Pill"
import SearchBar from "components/elements/SearchBar"
import Checkbox from "components/forms/Checkbox"
import Dropdown from "components/forms/Dropdown"
import PopoverMenu from "components/forms/PopoverMenu"
import PermissionGuard from "components/layouts/PermissionGuard"
import TablePageLayout from "components/layouts/TablePage"
import { ESortDirections, TDataRow } from "components/tables/SimpleTable"
import { CertificateProvider } from "context/CertificateContext"
import { useCurrentUserContext } from "context/CurrentUserContext"
import { useDownloadContext } from "context/DownloadContext"
import {
  useCertificatesForTableInfinite,
  useCertificatesRequestsForTableInfinite,
  useDeleteCertificate,
  ECertificateStatuses,
  TCertificateForTable,
  useDuplicateCertificate,
  ECertificateTypes,
  useCertificateTypes,
  ICertificateType,
} from "hooks/data"
import useWindowScrollPosition from "hooks/useWindowScrollPosition"
import { ELanguages, EUserRoles, LOCAL_STORAGE_KEYS } from "src/constants"

import Spinner from "../../components/elements/LoadingSpinner"

export enum EHeaderIds {
  STATUS = "StatusID",
  CERTIFICATE_TYPE = "TypeID",
  CERTIFICATE_ID = "CertificateID",
  EMPLOYEE_NAME = "EmployeeName",
  RESPONSIBLE_HR = "ResponsibleHRName",
  EDITOR = "EditorName",
  LAST_MODIFIED = "ModDate",
}

export function getLabelClassName(code: ECertificateStatuses) {
  switch (code) {
    case "done":
      return "labelGreen"
    case "in_progress":
      return "labelYellow"
    default:
      return "labelGray"
  }
}

export type TRowProps = {
  cells: {
    status: string
    certificateId: number
    certificateType: string
    employeeName: string
    responsibleHr: string
    editor: string
    lastModified: string
  }
  certificate: TCertificateForTable
  deleteDisabled: boolean
  duplicateDisabled: boolean
  editDisabled: boolean
  onRowClick: () => void
  onDeleteButtonClick: () => void
  onDuplicateButtonClick: () => void
  downloadDropdownMenuItems: ComponentProps<typeof Dropdown>["menuItems"]
}

type TCertificatesFilters = {
  onlyMyCertificates: boolean
  onlyOpenCertificates: boolean
  hideCertificateRequests: boolean
  certificateType: ICertificateType | "ALL"
  collaboratorHR?: number
  collaboratorLM?: number
  employee?: number
}

enum EViewModes {
  CARDS = "CARDS",
  TABLE = "TABLE",
}

export default function CertificatesPage(): ReactNode {
  const router = useRouter()

  const { t } = useTranslation("certificatesPage")
  const { t: tN } = useTranslation(["selectComponent", "certificateEditor"])
  const { t: tC } = useTranslation("certificatesPage")

  const { currentUser, isUser, isHr, isSuperHr, UILanguage } =
    useCurrentUserContext()
  const { downloadCertificate } = useDownloadContext()

  const windowScrollPosition = useWindowScrollPosition()

  const [searchTerm, setSearchTerm] = useState("")
  const [existingFiltersState] = useState(() => {
    const retrieved = localStorage.getItem(
      LOCAL_STORAGE_KEYS.FILTERS_CERTIFICATES
    )
    return retrieved ? (JSON.parse(retrieved) as TCertificatesFilters) : null
  })

  const [filters, setFilters] = useState<TCertificatesFilters>(() => {
    if (existingFiltersState) {
      return existingFiltersState
    }

    return {
      onlyMyCertificates: false,
      onlyOpenCertificates: false,
      hideCertificateRequests: false,
      certificateType: "ALL",
    }
  })

  const [viewMode, setViewMode] = useState<EViewModes>(() => {
    return EViewModes.TABLE
    // const existingState = localStorage.getItem(
    //   LOCAL_STORAGE_KEYS.VIEW_CERTIFICATES
    // )
    // if (existingState) {
    //   return existingState as EViewModes
    // }
    // return EViewModes.CARDS
  })
  const [showModalCreateNewCertificate, setShowModalCreateNewCertificate] =
    useState(false)

  const [showEmptyState, setShowEmptyState] = useState (false);
  const [certificateIdToDelete, setCertificateIdToDelete] = useState<
    number | null
  >(null)
  const [sortDirection, setSortDirection] = useState(ESortDirections.DESCENDING)
  const [sortBy, setSortBy] = useState(EHeaderIds.LAST_MODIFIED)
  const [requestedCertificate, setRequestedCertificate] = useState<CertificateRequestRow | undefined>(undefined);
  const certificateTypesQuery = useCertificateTypes()
  const deleteCertificate = useDeleteCertificate()
  const certificatesRequestsForTableInfiniteQueryParams = {
    $top: 40,
    $orderby: "CreaDate desc",
    searchterm: searchTerm,
    $skip: 0,
  }

  const certificatesForTableInfiniteQueryParams = {
    $top: 40,
    $orderby: `${sortBy} ${sortDirection}`,
    searchterm: searchTerm,
    $filter: "",
  }

  if (filters.onlyMyCertificates && currentUser) {
    certificatesForTableInfiniteQueryParams.$filter = isUser
      ? `EditorID eq ${currentUser.BenutzerID}`
      : `( ResponsibleHRID eq ${currentUser.BenutzerID} or EditorID eq ${currentUser.BenutzerID} )`
  }
  if (filters.collaboratorHR) {
    if (certificatesForTableInfiniteQueryParams.$filter) {
      certificatesForTableInfiniteQueryParams.$filter += " and "
    }
    certificatesForTableInfiniteQueryParams.$filter += `( ResponsibleHRID eq ${filters.collaboratorHR} or LocalHRID eq ${filters.collaboratorHR} )`
  }
  if (filters.collaboratorLM) {
    if (certificatesForTableInfiniteQueryParams.$filter) {
      certificatesForTableInfiniteQueryParams.$filter += " and "
    }
    certificatesForTableInfiniteQueryParams.$filter += `( LineManagerID eq ${filters.collaboratorLM} )`
  }
  if (filters.employee) {
    if (certificatesForTableInfiniteQueryParams.$filter) {
      certificatesForTableInfiniteQueryParams.$filter += " and "
    }
    certificatesForTableInfiniteQueryParams.$filter += `( EmployeeID eq ${filters.employee} )`
  }
  if (filters.onlyOpenCertificates) {
    if (certificatesForTableInfiniteQueryParams.$filter) {
      certificatesForTableInfiniteQueryParams.$filter += " and "
    }

    certificatesForTableInfiniteQueryParams.$filter += "( StatusID eq 8 )"
  }
  if (filters.certificateType !== "ALL") {
    if (certificatesForTableInfiniteQueryParams.$filter) {
      certificatesForTableInfiniteQueryParams.$filter += " and "
    }

    certificatesForTableInfiniteQueryParams.$filter += `TypeID eq ${filters.certificateType.CatZeugnisTypID}`
  }

  const certificatesRequestsForTableInfiniteQuery = useCertificatesRequestsForTableInfinite(
    certificatesRequestsForTableInfiniteQueryParams,
    {
      staleTime: 1000 * 60 * 10,
      enabled: true,
      keepPreviousData: true,
    }
  )
  const certificatesForTableInfiniteQuery = useCertificatesForTableInfinite(
    certificatesForTableInfiniteQueryParams,
    {
      staleTime: 1000 * 60 * 10,
      enabled: existingFiltersState?.onlyMyCertificates
        ? Boolean(currentUser)
        : true,
      keepPreviousData: true,
    }
  )

  const duplicateCertificate = useDuplicateCertificate({
    onSuccess(response) {
      if (isSuperHr) {
        void router.push(`/certificates/${response.ZeugnisID}/edit#basic`)
      } else {
        void certificatesForTableInfiniteQuery.refetch()
      }
    },
  })

  const handleFilterChange = useCallback(
    (filter: Partial<TCertificatesFilters>) => {
      const nextState = { ...filters, ...filter }
      setFilters(nextState)
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.FILTERS_CERTIFICATES,
        JSON.stringify(nextState)
      )
    },
    [filters]
  )

  useEffect(() => {
    if (!existingFiltersState && (isUser || isHr)) {
      setFilters((prevState) => {
        const nextState = {
          ...prevState,
          onlyMyCertificates: true,
        }
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.FILTERS_CERTIFICATES,
          JSON.stringify(nextState)
        )
        return nextState
      })
    }
  }, [existingFiltersState, isUser, isHr])

  useEffect(() => {
    if (
      certificatesForTableInfiniteQuery.isFetching ||
      !certificatesForTableInfiniteQuery.hasNextPage
    ) {
      return
    }
    if (
      document.body.scrollHeight > window.innerHeight &&
      windowScrollPosition.y + 5 >
        document.body.scrollHeight - window.innerHeight
    ) {
      void certificatesForTableInfiniteQuery.fetchNextPage()
    }
  }, [windowScrollPosition, certificatesForTableInfiniteQuery])

  const editableCertificateIds = useMemo(
    () =>
      Object.entries(currentUser?.ZeugnisAuthorizations ?? {}).reduce<number[]>(
        (accumulator, [certId, allowedActions]) =>
          allowedActions.includes("Zeugnisse_Put")
            ? accumulator.concat(Number(certId))
            : accumulator,
        []
      ),
    [currentUser]
  )

  const dataRows2 = useMemo(() => {
    return certificatesForTableInfiniteQuery.data?.pages.flatMap(
      (page) => page.data
    )
  }, [certificatesForTableInfiniteQuery])

  const dataRowsRequests = useMemo(() => {
    return certificatesRequestsForTableInfiniteQuery.data?.pages.flatMap(
      (page) => page.data
    )
  }, [certificatesRequestsForTableInfiniteQuery])
  const requestsTableRows = useCertificatesRequestsTableRows(dataRowsRequests ?? [])

  const dataRows = useMemo(
    () =>
      certificatesForTableInfiniteQuery.data?.pages.reduce(
        (result, page) => [
          ...result,
          ...page.data.map((certificate) => {
            const certificateId = certificate.CertificateID

            const isInProgressOrBlocked =
              certificate.StatusCode === "in_progress" ||
              certificate.StatusCode === "blocked"
            const deleteDisabled =
              (isInProgressOrBlocked &&
                !isSuperHr &&
                !(
                  isHr &&
                  currentUser?.BenutzerID === certificate.ResponsibleHRID
                )) ||
              isUser
            const editDisabled =
              (isHr && !editableCertificateIds.includes(certificateId)) ||
              (isUser && !editableCertificateIds.includes(certificateId))

            const downloadCertificateAs = (type: "PDF" | "DOCX") => {
              const certificateName = certificateTypesQuery.data?.find(
                ({ CatZeugnisTypID }) => CatZeugnisTypID === certificate.TypeID
              )

              downloadCertificate.mutate({
                certificateId,
                type,
                completed: certificate.StatusCode === ECertificateStatuses.DONE,
                // WORKAROUND:
                // Server returns rubbish instead of legit name for employee confirmation -
                // but only when UI langauge is set to DE.
                // RESOLVED BY:
                // https://github.com/skriba-ch/skriba-ink/issues/350
                ...(certificate.TypeID === ECertificateTypes.CONFIRMATION &&
                  certificateName &&
                  UILanguage === ELanguages.DE && {
                    customName: `${certificateName.BezeichnungML[UILanguage]} ${certificate.EmployeeName}`,
                  }),
              })
            }
            const downloadDropdownMenuItems: ComponentProps<
              typeof Dropdown
            >["menuItems"] = [
              {
                text: tN("certificateEditor:actions.downloadAs", {
                  type: "PDF",
                }),
                onClick(event) {
                  event.stopPropagation()

                  downloadCertificateAs("PDF")
                },
                Icons: {
                  Active: NotesIcon,
                  Inactive: NotesIcon,
                },
              },
              {
                text: tN("certificateEditor:actions.downloadAs", {
                  type: "DOCX",
                }),
                onClick(event) {
                  event.stopPropagation()

                  downloadCertificateAs("DOCX")
                },
                Icons: {
                  Active: NotesIcon,
                  Inactive: NotesIcon,
                },
              },
            ]

            return {
              id: certificateId,
              cells: {
                status: certificate.StatusName,
                certificateId,
                certificateType: certificate.TypeName,
                employeeName: certificate.EmployeeName,
                responsibleHr: certificate.ResponsibleHRName,
                editor: certificate.EditorName,
                lastModified: dayjs(certificate.ModDate).format(
                  "DD.MM.YYYY HH:mm"
                ),
              },
              certificate,
              deleteDisabled,
              duplicateDisabled: isInProgressOrBlocked || isUser,
              editDisabled,
              onDuplicateButtonClick() {
                duplicateCertificate.mutate(certificateId)
              },
              onDeleteButtonClick() {
                setCertificateIdToDelete(certificateId)
              },
              onRowClick() {
                const path = `/certificates/${certificateId}/edit#${
                  isUser ? "tasks" : "basic"
                }`
                if (!editDisabled) {
                  void router.push(path)
                }
              },
              downloadDropdownMenuItems: isUser
                ? downloadDropdownMenuItems.slice(0, 1)
                : downloadDropdownMenuItems,
            }
          }),
        ],
        [] as TDataRow<TRowProps>[]
      ),
    [
      certificatesForTableInfiniteQuery,
      currentUser?.BenutzerID,
      isHr,
      isSuperHr,
      isUser,
      editableCertificateIds,
      router,
      duplicateCertificate,
      UILanguage,
      downloadCertificate,
      certificateTypesQuery.data,
      tN,
    ]
  )

  const selectOptionAll = {
    value: "ALL",
    primary: tN("selectComponent:all"),
  } as const

  const activeFiltersCount = useMemo(() => {
    let result = 0

    if (filters.onlyMyCertificates) {
      result += 1
    }

    if (filters.onlyOpenCertificates) {
      result += 1
    }

    if (filters.hideCertificateRequests) {
      result += 1
    }

    if (filters.certificateType !== "ALL") {
      result += 1
    }
    if (filters.collaboratorHR) {
      result += 1
    }
    if (filters.collaboratorLM) {
      result += 1
    }
    if (filters.employee) {
      result += 1
    }

    return result
  }, [filters])

  const getTotalCount = () => {
    const certificatesCount = Number(certificatesForTableInfiniteQuery.data?.pages[0]?.headers.totalcount)
    const certificatesRequestCount = Number(certificatesRequestsForTableInfiniteQuery.data?.pages[0]?.headers.totalcount)

    if (filters.hideCertificateRequests && Number.isInteger(certificatesCount) ) {
      return certificatesCount
    }

    if (Number.isInteger(certificatesCount) && Number.isInteger(certificatesRequestCount)) {
      return certificatesCount + certificatesRequestCount
    }

    return 0
  }

  useEffect (() => {
    if (!certificatesForTableInfiniteQuery.isSuccess) {
      return
    }

    if (!certificatesRequestsForTableInfiniteQuery.isSuccess) {
      return
    }

    if (dataRows?.length === 0 && dataRows2?.length === 0 ) {
      setShowEmptyState(true)
    } else {
      setShowEmptyState(false)
    }
  }, [dataRows, dataRows2, certificatesForTableInfiniteQuery.isSuccess, certificatesRequestsForTableInfiniteQuery.isSuccess, activeFiltersCount])

  useEffect(() => {
    const { requestId } = router.query;

    if (!requestId || typeof requestId !== "string") {
      return;
    }

    const certificateRequest = requestsTableRows?.find((certificate) => certificate.CertificateRequestID === Number(requestId));

    if (!certificateRequest) {
      return;
    }

    setRequestedCertificate((prevRequestedCertificate) => {
      if (prevRequestedCertificate?.CertificateRequestID === certificateRequest.CertificateRequestID) {
        return prevRequestedCertificate;
      }

      return certificateRequest;
    });
    setShowModalCreateNewCertificate(true)
    const url = new URL(window.location.href);
    url.searchParams.delete("requestId");
    void router.replace(url);

  }, [router, requestsTableRows])

  const certificateTypeSelectOptions = [
    selectOptionAll,
    ...certificateTypesQuery.data?.map((certificateType) => ({
      value: certificateType,
      primary: certificateType.BezeichnungML[UILanguage],
    })) ?? []
  ]

  router.events.on("routeChangeComplete", (url) => {
    // console.log( 'routeChangeComplete', url )
    if (url === "/certificates") {
      void certificatesForTableInfiniteQuery.refetch()
    }
  })

  return (
    <CertificateProvider>
      <TablePageLayout title={t("title")} total={getTotalCount()}>

        <Head>
          <title>{`Skriba - ${t("title")}`}</title>
        </Head>

        {/* CONTROLS */}
        <div className="mb-6 flex">
          <div className="flex rounded-md">
            <div className="Search min-w-[20rem]">
              <SearchBar
                onChange={setSearchTerm}
                inputClassName="rounded-r-none"
                initialValue={searchTerm}
              />
            </div>

            <PopoverMenu
              buttonClassName="buttonSecondary h-full border-l-0 rounded-none rounded-r-md"
              buttonContent={
                <>
                  <FilterIcon className="icon mr-2" />
                  <span>{t("filter.button")}</span>
                  <span className="ml-1 text-sm">({activeFiltersCount})</span>
                </>
              }
            >
              <div className="relative grid gap-6 bg-white p-6 rounded-lg">
                <SelectGeneric<TCertificatesFilters["certificateType"]>
                  label={t("filter.certificateType")}
                  options={certificateTypeSelectOptions}
                  value={
                    filters.certificateType === "ALL"
                      ? certificateTypeSelectOptions[0]
                      : certificateTypeSelectOptions.find(
                        // @ts-ignore
                         (item) => item.value.CatZeugnisTypID === filters.certificateType.CatZeugnisTypID
                      )
                  }
                  onChange={({ value }) =>
                    handleFilterChange({
                      certificateType: value,
                    })
                  }
                />
                <Checkbox
                  id="onlyMyCertificates"
                  label={`${t("filter.onlyMyCertificates")}`}
                  initiallyChecked={filters.onlyMyCertificates}
                  onChange={(isOn) =>
                    handleFilterChange({ onlyMyCertificates: isOn })
                  }
                />
                <Checkbox
                  id="onlyOpenCertificates"
                  label={`${t(`filter.onlyOpenCertificates`)}`}
                  initiallyChecked={filters.onlyOpenCertificates}
                  onChange={(isOn) =>
                    handleFilterChange({ onlyOpenCertificates: isOn })
                    }
                />
                <Checkbox
                  id="hideCertificateRequests"
                  label={`${t(`filter.hideCertificateRequests`)}`}
                  initiallyChecked={filters.hideCertificateRequests}
                  onChange={(isOn) =>
                    handleFilterChange({ hideCertificateRequests: isOn })
                  }
                />
                <ComboboxEmployee
                  hasReset
                  label={`${t(`filter.employee`)}`}
                  onChange={(employee) => {
                    handleFilterChange({
                      employee,
                    })
                  }}
                  value={filters.employee}
                />
                <ComboboxUser
                  hasReset
                  label={`${t(`filter.collaboratorHR`)}`}
                  onChange={(collaboratorHR) => {
                    handleFilterChange({ collaboratorHR })
                  }}
                  value={filters.collaboratorHR}
                  searchRole="HR"
                />
                <ComboboxUser
                  hasReset
                  label={`${t(`filter.collaboratorLM`)}`}
                  onChange={(collaboratorLM) => {
                    handleFilterChange({ collaboratorLM })
                  }}
                  value={filters.collaboratorLM}
                />
              </div>
            </PopoverMenu>
          </div>

          <div className="ml-6 mr-auto flex">
            {/* Temporarily disabled */}
            {/* <Button
              secondary
              onClick={() => {
                setViewMode(EViewModes.TABLE)
                localStorage.setItem(
                  LOCAL_STORAGE_KEYS.VIEW_CERTIFICATES,
                  EViewModes.TABLE
                )
              }}
              className={classNames([
                "!min-w-[3rem]",
                "rounded-r-none",
                viewMode === EViewModes.TABLE
                  ? "bg-yellow-200 hover:bg-yellow-300"
                  : null,
              ])}
            >
              <TableIcon className="icon" />
            </Button>
            <Button
              secondary
              onClick={() => {
                setViewMode(EViewModes.CARDS)
                localStorage.setItem(
                  LOCAL_STORAGE_KEYS.VIEW_CERTIFICATES,
                  EViewModes.CARDS
                )
              }}
              className={classNames([
                "!min-w-[3rem]",
                "rounded-l-none border-l-0",
                viewMode === EViewModes.CARDS
                  ? "bg-yellow-200 hover:bg-yellow-300"
                  : null,
              ])}
            >
              <CardsIcon className="icon" />
            </Button> */}
          </div>

          {/* NEW CERTIFICATE */}
          <PermissionGuard view={[EUserRoles.HR, EUserRoles.SUPER_HR]}>
            <Button
              onClick={() => setShowModalCreateNewCertificate(true)}
              Icon={PlusIcon}
            >
              <span>{t("newCertificate")}</span>
            </Button>
          </PermissionGuard>
        </div>

        {/* CERTIFICATES LIST */}
        <div className="flex flex-col">
          {certificatesForTableInfiniteQuery.isLoading && (
            <div className="mt-48 flex justify-center">
              <Spinner />
            </div>
          )}
          <div className="overflow-x-auto">
            <div>
              {/* TABLE */}
              {viewMode === EViewModes.TABLE && (
                <CertificatesTable
                  data={dataRows2 ?? []}
                  extraData={!filters.hideCertificateRequests ? dataRowsRequests : []}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  setSortBy={(sortState?: SortingState) => {
                    if (sortState && sortState[0]) {
                      setSortDirection(
                        sortState[0].desc
                          ? ESortDirections.DESCENDING
                          : ESortDirections.ASCENDING
                      )
                      setSortBy(sortState[0].id as EHeaderIds)
                    } else {
                      setSortDirection(ESortDirections.DESCENDING)
                      setSortBy(EHeaderIds.LAST_MODIFIED)
                    }
                  }}
                  onRowClick={(certificate) => {
                    if (!certificate.isCertificateRequest) {
                      return;
                    }

                    setRequestedCertificate(certificate)
                    setShowModalCreateNewCertificate(true)
                  }}
                />
              )}
              {/* {viewMode === EViewModes.TABLE && (
                <SimpleTable
                  dataHeaders={headers}
                  {...{ dataRows, sortBy }}
                  RowComponent={Row}
                />
              )} */}

              {/* CERTIFICATE CARDS */}
              {viewMode === EViewModes.CARDS && (
                <div className="GridCertificates grid grid-cols-1 gap-6 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                  {dataRows?.map(
                    ({
                      id,
                      cells,
                      certificate,
                      editDisabled,
                      duplicateDisabled,
                      onRowClick,
                      onDeleteButtonClick,
                      onDuplicateButtonClick,
                      downloadDropdownMenuItems,
                    }) => {
                      const dropdownMenuItems = []

                      const { Termin, StatusCode } = certificate
                      const isOverdue = dayjs(Termin)
                        .add(1, "day")
                        .isBefore(dayjs())
                      /* eslint-disable-next-line */
                      const text = tC<any>("certificateStatuses.overdue")

                      if (!editDisabled) {
                        if (
                          certificate.StatusCode === ECertificateStatuses.DONE
                        ) {
                          dropdownMenuItems.push({
                            onClick: onRowClick,
                            text: t("actions.view"),
                            Icons: {
                              Active: FindIcon,
                              Inactive: FindIcon,
                            },
                          })
                        } else {
                          dropdownMenuItems.push({
                            onClick: onRowClick,
                            text: t("actions.edit"),
                            Icons: {
                              Active: PencilIcon,
                              Inactive: PencilIcon,
                            },
                          })
                        }

                        if (!isUser && !duplicateDisabled) {
                          dropdownMenuItems.push({
                            onClick: onDuplicateButtonClick,
                            text: t("actions.duplicate"),
                            Icons: {
                              Active: DuplicateIcon,
                              Inactive: DuplicateIcon,
                            },
                          })
                        }
                      }

                      dropdownMenuItems.push(...downloadDropdownMenuItems)
                      if (!editDisabled && !isUser) {
                        dropdownMenuItems.push({
                          onClick: onDeleteButtonClick,
                          text: t("actions.delete"),
                          Icons: {
                            Active: GarbageIcon,
                            Inactive: GarbageIcon,
                          },
                        })
                      }

                      return (
                        <div
                          key={id}
                          // secondary
                          // disabled={editDisabled}
                          className={classNames([
                            "GridItemCertificate",
                            "relative",
                            "flex flex-col items-start gap-6",
                            "rounded-md",
                            "border border-black !p-6",
                            "hover:bg-gray-100",
                            "disabled:!bg-white",
                            "disabled:hover:!bg-white",
                          ])}
                        >
                          <button
                            type="button"
                            className="absolute left-0 top-0 h-full w-full"
                            onClick={onRowClick}
                          >
                            <span className="sr-only">Open certificate</span>
                          </button>
                          <div
                            // type="button"
                            className="z-9 absolute right-4 top-2"
                            title="Certificate actions"
                            // onClick={(event) => event.stopPropagation()}
                          >
                            <Dropdown
                              button={{
                                content: (
                                  <HorizontalDotsIcon className="iconL cursor-pointer" />
                                ),
                                className: classNames([
                                  "grid items-center p-1.5",
                                  "rounded-full hover:bg-gray-200 fill-transparent hover:fill-black",
                                ]),
                              }}
                              menuItems={dropdownMenuItems}
                            />
                          </div>

                          {isOverdue && StatusCode !== "done" ? (
                            <Pill type="labelRed">{text}</Pill>
                          ) : (
                            <Pill
                              type={getLabelClassName(certificate.StatusCode)}
                            >
                              {cells.status}
                            </Pill>
                          )}

                          <div className="flex flex-col items-start">
                            <div className="text-base font-semibold">
                              {cells.employeeName}
                            </div>
                            <div className="mt-1 flex items-center gap-2">
                              <div>{cells.certificateType}</div>
                              <div className="labelGray">
                                #{cells.certificateId}
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center gap-4">
                            <div className="labelBlue flex items-center">
                              <div className="mr-2 h-2 w-2 rounded-full bg-blue-400" />
                              {cells.editor}
                            </div>
                            <div className="labelGray">
                              #{cells.responsibleHr}
                            </div>
                          </div>
                          <div className="text-gray-500">
                            {t("tableHeaders.lastModified")}:{" "}
                            {cells.lastModified}
                          </div>
                        </div>
                      )
                    }
                  )}
                </div>
              )}

              {certificateIdToDelete && (
                <DeleteConfirmationModal
                  show={Boolean(certificateIdToDelete)}
                  onClose={() => setCertificateIdToDelete(null)}
                  onConfirm={async () => {
                    await deleteCertificate.mutateAsync(certificateIdToDelete)
                    void certificatesForTableInfiniteQuery.refetch()
                  }}
                />
              )}
            </div>
          </div>
        </div>

        {showEmptyState && (
          (activeFiltersCount === 0 && searchTerm === "") ? (
            <EmptyState title={t("emptyState.noResults.title")} body={t("emptyState.noResults.body")} />
          ) : (
            <EmptyState title={t("emptyState.noResultsFilters.title")} body={t("emptyState.noResultsFilters.body")} />
          )
        )}

        {showModalCreateNewCertificate && (
          <CreateNewCertificateModal
            open={showModalCreateNewCertificate}
            setOpen={() => {
              setShowModalCreateNewCertificate(false)
              setRequestedCertificate(undefined)
            }}
            setCertificateSearchTerm={setSearchTerm}
            requestedCertificate={requestedCertificate}
          />
        )}
      </TablePageLayout>
    </CertificateProvider>
  )
}
