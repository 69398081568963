/* eslint-disable @typescript-eslint/no-redeclare */
// eslint-disable-next-line import/no-extraneous-dependencies
import z from "zod"

enum GendersEnum {
  MALE = 1,
  FEMALE = 2,
}

export const Genders = z.nativeEnum(GendersEnum)
export type Genders = z.infer<typeof Genders>

export const DateFormat = z.string().regex(/^\d{4}-\d{2}-\d{2}$/)
export type DateFormat = z.infer<typeof DateFormat>

export const JobLiteSchema = z.object({
  jobType: z.string(),
  levelOfEmployment: z.number(),
  department: z.string(),
  jobTitle: z.string(),
  startDate: DateFormat,
})

export type JobLiteSchema = z.infer<typeof JobLiteSchema>

export const EmployeeData = z.object({
  dateOfBirth: DateFormat.nullable(),
  citizenship: z.string().optional(),
  gender: Genders,
  fullName: z.string().optional(),
  employmentEndDate: DateFormat.nullable(),
  jobs: JobLiteSchema.array(),
})

export type EmployeeData = z.infer<typeof EmployeeData>

export const AvailableLanguages = z.enum(["de", "en", "fr", "it"])
export type AvailableLanguages = z.infer<typeof AvailableLanguages>

export const GenerateType = z.enum([
  "reword-sentence",
  "certificate-language-changed",
  "certificate-type-changed",
])

export type GenerateType = z.infer<typeof GenerateType>

export const GenerateInput = z.object({
  original: z.string(),
  sentence: z.string(),
  temperature: z.number().optional(),
  certificateType: z.enum(["interim", "final"]),
  language: AvailableLanguages,
  type: GenerateType,
  debug: z.boolean().optional(),
})

export type GenerateInput = z.infer<typeof GenerateInput>

export const GenerateCertificateOutputSuccessDebug = z.object({
  prompt: z.string(),
  response: z.string(),
  temperature: z.number(),
  model: z.string(),
})

export type GenerateCertificateOutputSuccessDebug = z.infer<
  typeof GenerateCertificateOutputSuccessDebug
>

export const GenerateCertificateOutputSuccess = z.object({
  intro: z.string(),
  status: z.literal("success"),
  debug: GenerateCertificateOutputSuccessDebug.optional(),
})

export const GenerateCertificateOutputError = z.object({
  error: z.string(),
  status: z.literal("error"),
})

export const GenerateCertificateOutput = z.union([
  GenerateCertificateOutputSuccess,
  GenerateCertificateOutputError,
])

export type GenerateCertificateOutput = z.infer<
  typeof GenerateCertificateOutput
>

export const IntroCustomizedTemplateStringReplacementSchema = z.object({
  original: z.string(),
  intro: z.string(),
  isEdited: z.boolean(),
})

export type IntroCustomizedTemplateStringReplacementSchema = z.infer<
  typeof IntroCustomizedTemplateStringReplacementSchema
>

export const InlineEditSections = z.enum(["intro", "conclusion", "education", "evaluations"])
export type InlineEditSections = z.infer<typeof InlineEditSections>
