import { FC } from "react"
import { useTranslation } from "react-i18next"

import Menu from "@/components/ui/Menu"
import {
  Download as DownloadIcon,
  EditCertificate as EditCertificateIcon,
  Certificates as CertificateIcon,
  Duplicate as DuplicateIcon,
  Garbage as GarbageIcon,
  HorizontalDots } from "assets/icons"
import { useCurrentUserContext } from "context/CurrentUserContext"
import { useConfirm } from "hooks/useConfirm"

import { CertificateRow } from "./CertificatesTable"
import useCertificateRowAction from "./useCertificateRowAction"

type Props = {
  data: CertificateRow
}

const CertificateRowMenu: FC<Props> = ({ data }) => {
  const { isUser } = useCurrentUserContext()
  const { t: tDownloads } = useTranslation("certificateEditor", {
    keyPrefix: "actions",
  })
  const { t: tActions } = useTranslation("certificatesPage", {
    keyPrefix: "actions",
  })
  const { t: tModal } = useTranslation("modalComponent")
  const { t: tModalDelete } = useTranslation("modalComponent", {
    keyPrefix: "delete",
  })
  const { t: tCertificates } = useTranslation("certificatesPage", {
    keyPrefix: "deleteCertificateModal",
  })

  const getConfirmation = useConfirm({
    title: tCertificates("title", { titleData: data.CertificateID.toString() }),
    description: tCertificates("description", { descriptionData: data.EmployeeName }),
    confirmText: tModalDelete("confirm"),
    cancelText: tModal("cancel"),
  })

  const {
    downloadCertificateAs,
    duplicateCertificate,
    deleteCertificate,
    editCertificate,
  } = useCertificateRowAction({ data })

  return (
    <>
      <Menu
        options={[
          {
            label: tActions(data.StatusCode === "done" ? "view" : "edit"),
            disabled: data.editDisabled,
            onClick: () => editCertificate(),
            icon: <span className="icon" aria-hidden="true">{data.StatusCode === "done" ? <CertificateIcon /> : <EditCertificateIcon />}</span>
          },
          {
            label: tActions("duplicate"),
            disabled: data.duplicateDisabled,
            onClick: () => duplicateCertificate(),
            icon: <span className="icon" aria-hidden="true"><DuplicateIcon /></span>
          },
          {
            label: tDownloads("downloadAs", { type: "PDF" }),
            onClick: () => downloadCertificateAs("PDF"),
            icon: <span className="icon" aria-hidden="true"><DownloadIcon /></span>
          },
          {
            label: tDownloads("downloadAs", { type: "DOC" }),
            disabled: isUser,
            onClick: () => downloadCertificateAs("DOCX"),
            icon: <span className="icon" aria-hidden="true"><DownloadIcon /></span>
          },
          {
            type: "separator",
          },
          {
            type: "destructive",
            label: tActions("delete"),
            disabled: data.deleteDisabled,
            onClick: () =>
              getConfirmation()
                .then(() => deleteCertificate(data.CertificateID))
                .catch(() => {}),
            icon: <span className="icon" aria-hidden="true"><GarbageIcon /></span>
          },
        ]}
      >
        <button className="-mx-4 rounded-full p-2 hover:bg-gray-200">
          <HorizontalDots className="h-5 w-5 fill-white " />
        </button>
      </Menu>
    </>
  )
}

export default CertificateRowMenu
